import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader

import Layout from "../components/layout"
import { RichText } from "../components"
import * as Types from "../types"
import { ArrowRight } from "../icons/ArrowRight"

type Props = {
  pageContext: Types.ProjectWrapper
}

export default function ProjectPage(props: Props) {
  const { data } = props.pageContext
  const c = React.useRef<Carousel>(null)
  const { title, description, carousel } = data

  React.useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (!c.current) {
        return
      }
      switch (event.key) {
        case "ArrowRight":
          c.current.increment()
          break
        case "ArrowLeft":
          c.current.decrement()
          break
      }
    }

    window.addEventListener("keydown", handleKeydown)
    return () => {
      window.removeEventListener("keydown", handleKeydown)
    }
  }, [])

  return (
    <Layout>
      <section>
        <Carousel
          ref={c}
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          renderIndicator={(onClick, isSelected) => {
            return (
              <button
                css={{
                  border: "none",
                  borderRadius: "100px",
                  height: isSelected ? 9 : 6,
                  marginRight: 10,
                  transition: "all 200ms",
                  transform: isSelected ? "scaleY(1.1)" : undefined,
                  background: isSelected ? "#00000091" : "#00000029",
                }}
                onClick={onClick}
              ></button>
            )
          }}
          renderArrowNext={clickHandler => (
            <ArrowRightBtn onClick={clickHandler} style={{ right: 0 }} />
          )}
          renderArrowPrev={clickHandler => (
            <ArrowRightBtn
              onClick={clickHandler}
              style={{ left: 0, transform: "rotate(180deg)" }}
            />
          )}
          transitionTime={600}
          css={{ padding: "1rem" }}
        >
          {carousel.map(carouselItem => (
            <img
              css={{
                width: "100%",
                height: "70vh",
                objectFit: "contain",
                background: "white",
              }}
              src={carouselItem.photo.url}
              alt={carouselItem.photo.alt}
            />
          ))}
        </Carousel>
      </section>

      <section
        css={{
          margin: `0 auto`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: `0 2rem`,
        }}
      >
        <h1>{title.text}</h1>
        <div css={{ maxWidth: 760, lineHeight: 1.5 }}>
          <RichText content={description} />
        </div>
      </section>
    </Layout>
  )
}

function ArrowRightBtn(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      role="button"
      css={{
        position: "absolute",
        cursor: "pointer",
        top: "50%",
        transform: "translate(-50%)",
        zIndex: 2,
        ":hover": {
          color: "red",
        },
      }}
    >
      <ArrowRight />
    </div>
  )
}
